<script setup>
import { ref, watch } from 'vue'
import _ from 'lodash' // lodash already installed so why not use it!

const title = 'Promotion'
const searchTerm = ref('')
const searchTimeout = 3000
let searchDebounce = null

const categories = ref([
  { name: 'Bordeaux', active: true },
  { name: 'Burgundy', active: false },
  { name: 'Champagne', active: false }
])

const products = ref([
  { id: 1, image: '@/assets/images/demo/wines/1.png', title: 'Wine 1', description: 'Description for Wine 1' },
  { id: 2, image: '@/assets/images/demo/wines/2.png', title: 'Wine 2', description: 'Description for Wine 2' },
  { id: 3, image: '@/assets/images/demo/wines/3.png', title: 'Wine 3', description: 'Description for Wine 3' }
])

const products2 = ref([
  { id: 1, image: '@/assets/images/demo/wines/1.png', title: 'Wine 1', description: 'Description for Wine 1' },
  { id: 2, image: '@/assets/images/demo/wines/2.png', title: 'Wine 2', description: 'Description for Wine 2' },
  { id: 3, image: '@/assets/images/demo/wines/3.png', title: 'Wine 3', description: 'Description for Wine 3' },
  { id: 3, image: '@/assets/images/demo/wines/3.png', title: 'Wine 4', description: 'Description for Wine 3' },
  { id: 3, image: '@/assets/images/demo/wines/3.png', title: 'Wine 5', description: 'Description for Wine 3' },
  { id: 3, image: '@/assets/images/demo/wines/3.png', title: 'Wine 6', description: 'Description for Wine 3' },
  { id: 3, image: '@/assets/images/demo/wines/3.png', title: 'Wine 7', description: 'Description for Wine 3' },
  { id: 3, image: '@/assets/images/demo/wines/3.png', title: 'Wine 8', description: 'Description for Wine 3' },
  { id: 3, image: '@/assets/images/demo/wines/3.png', title: 'Wine 9', description: 'Description for Wine 3' }
])

const performSearch = () => {
  console.log('Searching for:', searchTerm.value)
  // Implement your search logic here
}

watch(searchTerm, () => {
  clearTimeout(searchDebounce)
  searchDebounce = setTimeout(performSearch, searchTimeout)
})

const selectCategory = (selectedCategory) => {
  categories.value.forEach((category) => {
    category.active = category.name === selectedCategory.name
  })
  // Implement your category selection logic here
}
</script>

<template>
  <f7-page class="shop-promotion-page">
    <template #fixed>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.stop="$h.goBack()">
            <font-awesome-icon :icon="['far', 'arrow-left']" fixed-width />
          </f7-link>
        </f7-nav-left>

        <f7-nav-title> {{ title }} </f7-nav-title>
      </f7-navbar>
    </template>

    <!-- SEARCH -->
    <div class="search-container">
      <input v-model="searchTerm" name="Search" type="text" placeholder="Search keyword" />
      <font-awesome-icon :icon="['far', 'search']" fixed-width />
    </div>

    <!-- GRID -->
    <div class="promotion-container">
      <!-- CATEGORY NAVIGATION -->
      <div class="left">
        <ul>
          <li v-for="category in categories" :key="category.name" :class="{ active: category.active }" @click="selectCategory(category)">
            {{ category.name }}
          </li>
        </ul>
      </div>

      <div class="right">
        <div class="item-container">
          <!-- Iterate over products array to display products -->
          <div class="item">
            <h2>Country</h2>

            <div class="product-container">
              <div v-for="product in products" :key="product.id" class="product">
                <p>{{ product.title }}</p>
              </div>
            </div>
          </div>

          <div class="item">
            <h2>Region</h2>

            <div class="product-container">
              <div v-for="product in products2" :key="product.id" class="product">
                <p>{{ product.title }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<style scoped>
.search-container {
  padding: 10px 20px;
  background: #f8f8f8;
  position: relative;
  margin: 0;
}
.search-container input {
  padding: 8px 20px 8px 50px;
  font-size: 13px;
  background: #fff;
  color: #212121;
  border-radius: 16px;
  margin: 0;
  width: 100%;
}
.search-container svg {
  position: absolute;
  top: 22px;
  font-size: 12px;
  left: 40px;
}
.promotion-container {
  display: grid;
  grid-template-columns: 120px 1fr;
  height: calc(100vh - 230px);
}
.promotion-container .left {
  background: #f8f8f8;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.promotion-container .left ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.promotion-container .left ul li {
  padding: 25px 5px 25px 18px;
  font-size: 13px;
  word-break: break-word;
  position: relative;
  cursor: pointer;
}
.promotion-container .left ul li:hover {
  background: #f2f2f2;
}
.promotion-container .left ul li.active {
  background: #fff;
  font-weight: bold;
  text-transform: uppercase;
}
.promotion-container .left ul li.active:after {
  content: ' ';
  position: absolute;
  width: 2px;
  height: 60%;
  left: 0;
  top: 20%;
  background: #b51c22;
}
.promotion-container .right {
  height: 100%;
  overflow: hidden;
}
.promotion-container .right .item-container {
  height: calc(100% - 40px);
  padding: 20px 20px;
  overflow-y: auto;
}
.promotion-container .right h2 {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.product-container {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.product-container .product {
  background: #f8f8f8;
  padding: 20px 10px;
  width: 65px;
  height: 60px;
  text-align: center;
  overflow: hidden;
  object-fit: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-container .product p {
  display: block;
  margin: 0;
  padding: 0;
}
.product-container .product img {
  height: 100%;
  width: 100%;
  object-fit: fill;
  display: block;
}
</style>
